import React from "react"

import { Button, Typography } from "@suraasa/placebo-ui"
import Image from "next/image"
import Link from "next/link"
import Graphic from "public/assets/landing_page/v2/cuate.webp"

import Confidential from "./shared/Confidential"

const FooterBookCall = () => (
  <div className="text-white bg-onSurface-900">
    <div className="flex flex-col w-full my-6 md:flex-row">
      <div className="flex flex-col w-full md:min-w-[470px] gap-3">
        <Typography className="" textAlign="left" variant="title1">
          1-on-1 Guidance for <br /> Your Teaching Career
        </Typography>
        <Typography color="surface.100" textAlign="left" variant="largeBody">
          Discuss your career goals with an expert & get a personalized roadmap
          for your future. It's online and it's FREE!
        </Typography>
        <div className="col-span-12 md:col-span-5 col-start-0">
          <div className="flex flex-col">
            <Button
              className="!text-white"
              component={Link}
              href="https://suraasa.co/KMFK"
            >
              Book a Call
            </Button>
            <Confidential className="mt-1 text-secondary-300" />
          </div>
        </div>
      </div>
      <div className="relative flex items-center justify-center w-full gap-3 mt-4 md:mt-0">
        <div className="absolute h-[150px] flex w-[280px]" />

        <Link
          className="w-full h-full"
          href="https://suraasa.co/KMFK"
          style={{
            gridRow: 1,
          }}
        >
          {/* <div className="xs:h-[350px] relative max-w-[485px] w-full m-auto h-[260px] ">
            <video
              className="absolute z-10 w-full h-full xs:w-auto"
              controls={false}
              src="https://assets.suraasa.com/misc/mentorship-session-preview.mp4"
              style={{ borderRadius: 18 }}
              width="100%"
              autoPlay
              disablePictureInPicture
              loop
              muted
              playsInline
            />
          </div> */}
          <div className="xs:h-[350px] relative max-w-[485px] w-full m-auto h-[260px] ">
            <Image alt="graphic" src={Graphic} />
          </div>
        </Link>
      </div>
    </div>
  </div>
)

export default FooterBookCall
