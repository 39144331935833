import React from "react"

import { Container, Divider, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import Image from "next/image"
import Link from "next/link"
import AndroidDownload from "public/assets/landing/learning/androidDownload.svg"
import Facebook from "public/assets/landing/learning/facebook.svg"
import Insta from "public/assets/landing/learning/insta.svg"
import IosDownload from "public/assets/landing/learning/iosDownload.svg"
import Linkedin from "public/assets/landing/learning/linkedin.svg"
import SurassaFooterLogo from "public/assets/landing/learning/suraasaFooterLogo.svg"
import SuraasaLogoNew from "public/assets/landing/learning/surasa-logo-white.svg"
import Twitter from "public/assets/landing/learning/twitter.svg"
import Youtube from "public/assets/landing/learning/youtube.svg"
import { createUseStyles } from "react-jss"

import { getPlatformURL } from "utils/helpers"

import FooterBookCall from "./landingPage/FooterBookCall"
import PreserveQueryParams from "./shared/PreserveQueryParams"

const useStyles = createUseStyles(theme => ({
  root: {
    background: theme.colors.secondary[900],
    overflowX: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "85% 10.5%",
    backgroundSize: "38%",

    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "95% 26%",
      backgroundSize: "35%",
      backgroundImage: "none",
    },

    "& a": {
      textDecoration: "none",
      color: theme.colors.secondary[400],
    },
  },

  dot: {
    width: "4px",
    height: "4px",
    borderRadius: "100%",
    background: " #94A3B8",
  },

  divider2: {
    height: "1px !important",
  },
}))

const footerData = {
  learn: [
    {
      text: "All Programmes",
      link: getPlatformURL("suraasa", "/store"),
      isExternal: false,
    },
    {
      text: "PgCTL",
      link: getPlatformURL(
        "suraasa",
        "/store/qualifications/professional-graduate-certificate-in-teaching-learning"
      ),
      isExternal: false,
    },
  ],
  jobs: [
    {
      text: "Explore Jobs",
      link: getPlatformURL("jobs", "/explore"),
      isExternal: false,
    },
    // { text: "Partner Schools", link: getPlatformURL("jobs", "/explore") },
    {
      text: "Hire from Suraasa",
      link: getPlatformURL("suraasa", "/jobs/school"),
      isExternal: false,
    },
  ],
  resources: [
    {
      text: "FAQs",
      link: getPlatformURL("suraasa", "/faqs"),
      isExternal: false,
    },
    // { text: "All Resources", link: "https://www.suraasa.com/resources" },
    {
      text: "Blog",
      link: getPlatformURL("suraasa", "/blog"),
      isExternal: false,
    },
    {
      text: "Masterclasses",
      link: getPlatformURL("suraasa", "/masterclasses"),
      isExternal: false,
    },
    {
      text: "Newsletter",
      link: getPlatformURL("suraasa", "/newsletter"),
      isExternal: false,
    },
    { text: "Podcast", link: "https://anchor.fm/suraasa", isExternal: true },
    {
      text: "All Resources",
      link: getPlatformURL("suraasa", "/resources"),
      isExternal: false,
    },
  ],
  more: [
    {
      text: "About Us",
      link: getPlatformURL("suraasa", "/about-us"),
      isExternal: false,
    },
    {
      text: "In the News",
      link: getPlatformURL("suraasa", "/in-the-news"),
      isExternal: false,
    },
    {
      text: "Success Stories",
      link: getPlatformURL("suraasa", "/success-stories"),
      isExternal: false,
    },
    {
      text: "Careers",
      link: getPlatformURL("suraasa", "/careers"),
      isExternal: false,
    },
    {
      text: "Solutions for schools",
      link: getPlatformURL("suraasa", "/solutions-for-schools"),
      isExternal: false,
    },
  ],
  termsOfUse: [
    {
      text: "Terms of Use",
      link: getPlatformURL("suraasa", "/terms-of-use"),
      isExternal: false,
    },
  ],
  privacyPolicy: [
    {
      text: "Privacy Policy",
      link: getPlatformURL("suraasa", "/privacy-policy"),
      isExternal: false,
    },
  ],
  contactUs: [
    {
      text: "Contact Us",
      link: "https://www.suraasa.com/contact-us",
      isExternal: false,
    },
    {
      text: "Talk to a Mentor",
      link: "https://www.suraasa.com/teaching-career-mentorship",
      isExternal: false,
    },
  ],
}

const footerImageData = {
  socialMediaLinks: [
    {
      logo: Linkedin,
      link: "https://suraasa.co/linkedin",
      name: "Linkedin",
    },
    {
      logo: Insta,
      link: "https://suraasa.co/instagram",
      name: "Insta",
    },
    {
      logo: Youtube,
      link: "https://suraasa.co/youtube",
      name: "Youtube",
    },
    {
      logo: Facebook,
      link: "https://suraasa.co/facebook",
      name: "Facebook",
    },
    { logo: Twitter, link: "https://suraasa.co/twitter", name: "Twitter" },
  ],
  downloadLinks: [
    {
      logo: IosDownload,
      link: "https://apps.apple.com/in/app/suraasa/id1531878030",
      name: "App Store",
    },
    {
      logo: AndroidDownload,
      link: "https://play.google.com/store/apps/details?id=com.suraasa.suraasa&hl=en_IN&gl=US",
      name: "Play Store",
    },
  ],
}

function FooterLink({
  isExternal = false,
  href: originalHref,
  text,
  className = "",
}: {
  isExternal?: boolean
  className?: string
  href: string
  text: string
}) {
  if (isExternal) {
    return (
      <PreserveQueryParams
        href={originalHref}
        render={href => (
          <Typography
            className={clsx("hover:underline", className)}
            color="secondary.400"
            component="a"
            // @ts-ignore support this in placebo
            href={href}
            rel="noreferrer"
            target="_blank"
            variant="body"
          >
            {text}
          </Typography>
        )}
      />
    )
  }
  return (
    <PreserveQueryParams
      href={originalHref}
      render={href => (
        <Typography
          className={clsx("hover:underline", className)}
          color="secondary.400"
          component={Link}
          // @ts-ignore support this in placebo
          href={href}
          style={{ cursor: "pointer" }}
          variant="body"
        >
          {text}
        </Typography>
      )}
    />
  )
}

export function CopyrightBar() {
  const classes = useStyles()
  return (
    <>
      <Divider className={clsx("mt-6", classes.divider2)} />
      <div className="my-3.5 flex sm:items-center justify-between flex-col-reverse flex-wrap sm:flex-nowrap sm:flex-row">
        <div className="flex items-center gap-1 mt-0.5 sm:mt-0">
          <div className="hidden -mb-1 sm:block">
            <Image alt="suraasa-logo" src={SurassaFooterLogo} />
          </div>

          <Typography color="onSurface.400" variant="smallBody">
            © {new Date().getFullYear()} Les Transformations Learning Pvt. Ltd.
          </Typography>
        </div>
        <div className="flex items-center gap-1">
          <FooterLink
            href={footerData.termsOfUse[0].link}
            text={footerData.termsOfUse[0].text}
            isExternal
          />
          <div className={classes.dot} />
          <FooterLink
            href={footerData.privacyPolicy[0].link}
            text={footerData.privacyPolicy[0].text}
            isExternal
          />
        </div>
      </div>
    </>
  )
}

function Footer({
  showBookCall = false,
  showLinksSection = true,
}: {
  showBookCall?: boolean
  showLinksSection?: boolean
}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {showBookCall && (
        <>
          <Container>
            <FooterBookCall />
          </Container>
          {showLinksSection && <Divider />}
        </>
      )}
      {showLinksSection && (
        <Container className="mt-6">
          <div className="flex flex-col xl:flex-row gap-5">
            <div className="shrink-0">
              <Image alt="suraasa-logo" src={SuraasaLogoNew} />
            </div>

            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-12 sm:col-span-3 order-0">
                <Typography
                  className="mb-0.75"
                  color="surface.500"
                  variant="strong"
                >
                  Learn
                </Typography>
                {footerData.learn.map((item, index) => (
                  <FooterLink
                    className="mb-1"
                    href={item.link}
                    isExternal={item.isExternal}
                    key={index}
                    text={item.text}
                  />
                ))}
              </div>
              <div className="col-span-12 sm:col-span-3 order-4">
                <Typography
                  className="mb-0.75"
                  color="surface.500"
                  variant="strong"
                >
                  Jobs
                </Typography>
                {footerData.jobs.map((item, index) => (
                  <FooterLink
                    className="mb-1"
                    href={item.link}
                    isExternal={item.isExternal}
                    key={index}
                    text={item.text}
                  />
                ))}
              </div>
              <div className="col-span-12 sm:col-span-3 order-4">
                <Typography
                  className="mb-0.75"
                  color="surface.500"
                  variant="strong"
                >
                  Resources
                </Typography>
                {footerData.resources.map((item, index) => (
                  <FooterLink
                    className="mb-1"
                    href={item.link}
                    isExternal={item.isExternal}
                    key={index}
                    text={item.text}
                  />
                ))}
              </div>
              <div className="col-span-12 sm:col-span-3 order-4">
                <Typography
                  className="mb-0.75"
                  color="surface.500"
                  variant="strong"
                >
                  More
                </Typography>
                {footerData.more.map((item, index) => (
                  <FooterLink
                    className="mb-1"
                    href={item.link}
                    isExternal={item.isExternal}
                    key={index}
                    text={item.text}
                  />
                ))}
              </div>
              <div className="col-span-12 sm:col-span-3 order-2 sm:order-4">
                <Typography
                  className="mb-0.75"
                  color="surface.500"
                  variant="strong"
                >
                  Get in Touch
                </Typography>
                {footerData.contactUs.map((item, index) => (
                  <FooterLink
                    className="mb-1"
                    href={item.link}
                    isExternal={item.isExternal}
                    key={index}
                    text={item.text}
                  />
                ))}
              </div>
              <div className="col-span-12 sm:col-span-8 order-10">
                <Typography
                  className="mb-0.75"
                  color="surface.500"
                  variant="strong"
                >
                  Our Office
                </Typography>

                <div className="flex gap-2 sm:gap-5 flex-col sm:flex-row">
                  <Typography color="secondary.400" variant="body">
                    <span>India: 5th Floor, Tower-B, Unitech Cyber Park, </span>
                    <span>The Office Pass, Sector 39, </span>
                    <span>Gurugram, Haryana 122001</span>
                  </Typography>

                  <Typography color="secondary.400" variant="body">
                    <span>UAE: 2nd Floor, OneSpace Building 3, </span>
                    <span>Green Community, Dubai Investment Park 1, </span>
                    <span>Dubai, United Arab Emirates</span>
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between col-start-1 col-end-13 gap-5 mt-4 sm:items-center sm:flex-row xl:col-start-3 xl:col-end-13 sm:gap-0">
            <div className="flex items-center gap-1">
              {footerImageData.downloadLinks.map((item, index) => (
                <a
                  href={item.link}
                  key={index}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Image alt={item.name} src={item.logo} />
                </a>
              ))}
            </div>
            <div className="flex items-center gap-2">
              {footerImageData.socialMediaLinks.map((item, index) => (
                <a
                  href={item.link}
                  key={index}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Image alt={item.name} src={item.logo} />
                </a>
              ))}
            </div>
          </div>

          <CopyrightBar />
        </Container>
      )}
    </div>
  )
}

export default Footer
